<template>
  <div class="wrapper">
    <div class="nav nav-pills">
      <router-link
        to="/zap/add-new-order-zap"
        exact
        class="nav-link"
        active-class="active"
        >заказать запчасти для не гарантийных ремонтов</router-link
      >
      <router-link
        to="/zap/order-zap-list?type=inwork"
        class="nav-link"
        active-class="active"
        >список заказов</router-link
      >
    </div>
    <router-view class="view" />
  </div>
</template>
<script>
export default {
  name: "Zap"
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 5px;
}
.active {
  color: #fff;
  background: #007bff;
  cursor: default;
  text-decoration: none;
}
</style>
